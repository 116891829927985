if (process.env.NODE_ENV !== "development" && !process.env.REACT_APP_API_URL) {
  throw new Error(
    `NODE_ENV is set to an env other than "local" without REACT_APP_API_URL being set. Make sure to set REACT_APP_API_URL.`
  );
}

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/"
    : `https://${process.env.REACT_APP_API_URL}.vetspire.com/`;

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function buildHeaders() {
  return defaultHeaders;
}

function parseJSON(resp) {
  return resp.json();
}

export function apiPost(url, data, parse = true) {
  const body = JSON.stringify(data);

  const promise = fetch(API_URL + "/" + url, {
    method: "post",
    headers: buildHeaders(),
    body,
  });

  if (parse) return promise.then(parseJSON);
  return promise;
}

export function apiGet(url) {
  return fetch(API_URL + "/" + url, {
    headers: buildHeaders(),
  }).then(parseJSON);
}
